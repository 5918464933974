import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Button from '../components/Button';

const LandingPage = () => {
    const navigate = useNavigate(); // Hook for navigation

    return (
        <div style={styles.container}>
            {/* Hero Section */}
            <section style={styles.heroSection}>
                <h1 style={styles.heroHeading}>Welcome to BJJLocal</h1>
                <p style={styles.heroText}>
                    Your go-to app for finding Brazilian Jiu-Jitsu gyms in the DFW area.
                    We're just starting out, but we're excited to grow with the BJJ community!
                </p>
            </section>

            {/* Current Features Section */}
            <section style={styles.featuresSection}>
                <h2 style={styles.sectionHeading}>What We Offer Now</h2>
                <ul style={styles.featuresList}>
                    <li style={styles.featureItem}>Explore BJJ gyms in the DFW area.</li>
                    <li style={styles.featureItem}>View gym details, including facilities and classes offered.</li>
                </ul>
            </section>

            {/* Upcoming Features Section */}
            <section style={styles.upcomingSection}>
                <h2 style={styles.sectionHeading}>What We're Working On</h2>
                <ul style={styles.featuresList}>
                    <li style={styles.featureItem}>Create a profile and connect with your favorite gyms.</li>
                    <li style={styles.featureItem}>Expanded coverage to more cities and regions.</li>
                    <li style={styles.featureItem}>Social features to connect with other BJJ enthusiasts.</li>
                    <li style={styles.featureItem}>Gym reviews, ratings, and the ability to add your own feedback.</li>
                    <li style={styles.featureItem}>Exclusive deals and offers for members.</li>
                </ul>
                <Button
                    text="Explore Now"
                    onClick={() => navigate('/home')}
                    buttonType="primary"  // Use the 'primary' color scheme
                />
            </section>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
    },
    heroSection: {
        textAlign: 'center',
        marginBottom: '40px',
    },
    heroHeading: {
        fontSize: '36px',
        color: '#2c3e50',
        marginBottom: '20px',
    },
    heroText: {
        fontSize: '18px',
        color: '#7f8c8d',
    },
    featuresSection: {
        marginBottom: '40px',
    },
    upcomingSection: {
        marginBottom: '40px',
    },
    sectionHeading: {
        fontSize: '28px',
        color: '#2c3e50',
        marginBottom: '20px',
    },
    featuresList: {
        listStyleType: 'none', // Remove bullets
        paddingLeft: '0', // Remove left padding
    },
    featureItem: {
        fontSize: '18px',
        color: '#7f8c8d',
        marginBottom: '10px',
    },
};

export default LandingPage;
