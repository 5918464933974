import React, { useState } from 'react';
import Button from '../components/Button';

const AddGym = () => {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        region: '',
        geocodes: { lat: '', lon: '' },  // Geocodes updated to lat/lon
        website: '',
        description: '',
        phone: '',
        email: '',
        facebook: '',
        instagram: '',
        youtube: '',
        price: '',
        schedule: [{ dayOfWeek: '', time: '', giOrNoGi: '', ageGroup: '', difficulty: '' }],
        membershipTiers: [{ name: '', price: '', features: '' }],
        promoCodes: [{ code: '', discountPercentage: '', validUntil: '' }],
        classBookingLink: '',
        trialSignUpLink: ''
    });
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle geocodes input changes (lat/lon)
    const handleGeocodeChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            geocodes: {
                ...formData.geocodes,
                [name]: value,
            },
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset error and success messages
        setError(null);
        setSuccessMessage('');

        // Validate form data (Add your own validation logic)
        if (!formData.name || !formData.address || !formData.geocodes.lat || !formData.geocodes.lon || !formData.region) {
            setError('Please fill out all required fields.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/gyms/add-gym`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                throw new Error(message);
            }

            const data = await response.json();
            setSuccessMessage('Gym added successfully!');
            // Clear form after success
            setFormData({
                name: '',
                address: '',
                region: '',
                geocodes: { lat: '', lon: '' },
                website: '',
                description: '',
                phone: '',
                email: '',
                facebook: '',
                instagram: '',
                youtube: '',
                price: '',
                schedule: [{ dayOfWeek: '', time: '', giOrNoGi: '', ageGroup: '', difficulty: '' }],
                membershipTiers: [{ name: '', price: '', features: '' }],
                promoCodes: [{ code: '', discountPercentage: '', validUntil: '' }],
                classBookingLink: '',
                trialSignUpLink: ''
            });
            console.log(data);
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div style={styles.container}>
            <h2>Add a New Gym</h2>
            {error && <p style={styles.error}>{error}</p>}
            {successMessage && <p style={styles.success}>{successMessage}</p>}
            <form onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Name (required):</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Address (required) - street, city, TX zipcode:</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        required
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Region (required):</label>
                    <input
                        type="text"
                        name="region"
                        value={formData.region}
                        onChange={handleInputChange}
                        required
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Latitude (required):</label>
                    <input
                        type="text"
                        name="lat"
                        value={formData.geocodes.lat}
                        onChange={handleGeocodeChange}
                        required
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Longitude (required):</label>
                    <input
                        type="text"
                        name="lon"
                        value={formData.geocodes.lon}
                        onChange={handleGeocodeChange}
                        required
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Phone (required):</label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Email (required):</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Website (required):</label>
                    <input
                        type="text"
                        name="website"
                        value={formData.website}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Description (required):</label>
                    <input
                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Facebook:</label>
                    <input
                        type="text"
                        name="facebook"
                        value={formData.facebook}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Instagram:</label>
                    <input
                        type="text"
                        name="instagram"
                        value={formData.instagram}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>YouTube:</label>
                    <input
                        type="text"
                        name="youtube"
                        value={formData.youtube}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                {/* Add additional fields for schedule, membership tiers, promo codes, etc. here */}
                <Button
                    type="submit"
                    text="Add Gym"
                    buttonType="primary"
                />
            </form>
        </div>
    );
};

// Basic styles
const styles = {
    container: {
        padding: '20px',
        maxWidth: '500px',
        margin: '0 auto',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '16px',
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    error: {
        color: 'red',
        fontSize: '14px',
        marginBottom: '10px',
    },
    success: {
        color: 'green',
        fontSize: '14px',
        marginBottom: '10px',
    },
};

export default AddGym;
