import React, { useState, useEffect } from 'react';
import Button from '../components/Button';

const NewsPage = () => {
    const [newsItems, setNewsItems] = useState([]);  // Store news items
    const [page, setPage] = useState(1);  // Track the current page for pagination
    const [loading, setLoading] = useState(false);  // Track loading state
    const [error, setError] = useState(null);  // Track error state
    const [hasMore, setHasMore] = useState(true);  // Check if there's more news to load

    // Fetch news data
    const fetchNews = async (page) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`/api/v1/news?page=${page}`);
            if (!response.ok) {
                throw new Error('Failed to fetch news');
            }
            const data = await response.json();

            // Check if more news is available
            if (data.news.length === 0) {
                setHasMore(false);  // No more news to load
            } else {
                setNewsItems(prevNews => [...prevNews, ...data.news]);  // Append new news
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNews(page);  // Fetch news when the component mounts and when `page` changes
    }, [page]);

    // Handle "Load More" button click
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Latest News</h1>

            {/* Render News Items */}
            {newsItems.length > 0 ? (
                newsItems.map((news, index) => (
                    <div key={news.id} style={styles.newsItem}>
                        <h2>{news.title}</h2>
                        <p>{news.summary}</p>
                    </div>
                ))
            ) : (
                !loading && <p>No news found.</p>  // Display if no news is found and not loading
            )}

            {/* Show loading indicator */}
            {loading && <p>Loading more news...</p>}

            {/* Show error if there's an issue */}
            {error && <p style={styles.error}>Error: {error}</p>}

            {/* Load More button */}
            {hasMore && !loading && (
                <Button
                    onClick={handleLoadMore}
                    text="Load More"
                    buttonType="primary" 
                />
            )}
        </div>
    );
};

// Basic styles
const styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        textAlign: 'left',
    },
    heading: {
        fontSize: '32px',
        marginBottom: '20px',
        color: '#333',
    },
    newsItem: {
        marginBottom: '20px',
        padding: '15px',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    error: {
        color: 'red',
        fontSize: '16px',
    },
};

export default NewsPage;
