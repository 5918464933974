import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
import Button from '../components/Button';

const UserProfile = () => {
    const { currentUser, logout } = useAuth(); // Access the current user from AuthContext
    const navigate = useNavigate();  // Hook to navigate programmatically

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        console.log('User data:', currentUser);
    }, [currentUser]);

    // Check if user data is present
    if (!currentUser || !currentUser.token) {
        return <p>Loading user data...</p>;
    }

    const { firstName, lastName, email, beltRank, token } = currentUser;

    const handleResetPassword = () => {
        // Navigate to the reset password page and include the token as a query parameter
        navigate(`/reset-password?token=${token}`);
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Welcome, {firstName} {lastName}</h2>
            <div style={styles.detailsContainer}>
                <p><strong>First Name:</strong> {firstName}</p>
                <p><strong>Last Name:</strong> {lastName}</p>
                <p><strong>Email:</strong> {email}</p>
                <p><strong>Belt Rank:</strong> {beltRank}</p>
            </div>
            <Button
                text="Reset Password"
                onClick={handleResetPassword}
                buttonType="primary"
            />
            <Button
                text="Logout"
                onClick={logout}
                buttonType="primary"
            />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        margin: '0 auto',
        backgroundColor: '#fff',
    },
    heading: {
        marginBottom: '20px',
        fontSize: '28px',
        color: '#333',
    },
    detailsContainer: {
        textAlign: 'left',
        width: '100%',
        marginBottom: '20px',
    },
};

export default UserProfile;
