import React, { useState, useContext, useEffect } from 'react';
import { GymContext } from '../contexts/GymContext';
import Loading from '../components/Loading';
import Error from '../components/Error';
import GymDisplay from '../components/GymDisplay';
import ContactForm from '../components/ContactForm';
import Button from '../components/Button';
import SearchComponent from '../components/SearchComponent'; 
import BackToTopButton from '../components/BackToTopButton';

function Home() {
    const { gyms, loading, error } = useContext(GymContext);
    const [viewOption, setViewOption] = useState('grid');
    const [showContactForm, setShowContactForm] = useState(false);
    const [searchText, setSearchText] = useState(''); // Added state for search text

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Filter gyms based on search text
    const filteredGyms = gyms.filter(gym =>
        gym.name.toLowerCase().includes(searchText.toLowerCase()) ||  // Match by name
        gym.location.formatted_address.toLowerCase().includes(searchText.toLowerCase()) // Match by address
    );

    if (loading) return <Loading />;
    if (error) return <Error error={error} />;
    
    return (
        <div>
            {/* Header and Contact Section */}
            <div style={styles.rowOne}>
                <h1 style={styles.heading }>Welcome to BJJLocal</h1>
                <h3 style={styles.subHeading}>Find Brazilian Jiu-Jitsu gyms in the Dallas Fort Worth Metroplex</h3>
                <p style={styles.paragraph}>We're just getting started, but we have a lot of BIG ideas we're working on. If there are features you'd like to see, or if you have feedback in general, click the button below and let us know!</p>
                <div style={styles.buttonContainer}>
                <Button
                    text="Contact Us"
                    onClick={() => setShowContactForm(true)}
                    buttonType="primary"  
                    />
                    </div>
                {/* Contact Form Modal */}
                {showContactForm && (
                    <div style={styles.modalOverlay}>
                        <div style={styles.modalContent}>
                            <ContactForm closeForm={() => setShowContactForm(false)} />
                        </div>
                    </div>
                )}
            </div>

            {/* Search Section */}
            <div style={styles.rowTwo}>
                <SearchComponent
                    searchText={searchText} 
                    onSearchChange={setSearchText} 
                />
            </div>

            {/* Gym Display Section */}
            <div style={ styles.rowThree }>
                <GymDisplay gyms={filteredGyms} viewOption={viewOption} />
            </div>

            <div>
                <BackToTopButton />
            </div>
        </div>
    );
}

const styles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContent: {
        width: '90%',
        maxWidth: '500px',
    },
    rowOne: {
    },
    heading: {
        padding: '20px',
        fontSize: '2rem',
    },
    subHeading: {
        fontSize: '1.3rem',
    },
    paragraph: {
        padding: '20px',
        fontSize: '1.1rem',
    },
    rowTwo: {
        padding: '20px',
        backgroundColor: 'whitesmoke',
        borderTop: '1px solid lightgrey',
        borderBottom: '1px solid lightgrey',
    },
    buttonContainer: {
        marginBottom: '20px',
    },
    rowThree: {
        backgroundColor: 'whitesmoke',
    }
};

export default Home;
