import React, { useState } from 'react';
import Button from './Button';

const ContactForm = ({ closeForm }) => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [showStatus, setShowStatus] = useState(false); // State to control status visibility

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message,
                }),
            });

            if (response.ok) {
                setStatus('Thanks for your feedback!');
                setMessage('');
                setShowStatus(true);

                // Hide the status after 3 seconds
                setTimeout(() => {
                    setShowStatus(false);
                    closeForm();
                }, 3000);
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setStatus('Failed to send message');
        }
    };

    return (
        <section style={styles.contactSection}>
            <div style={styles.card}>
                <div style={styles.closeButton} onClick={closeForm}>
                    &times;
                </div>
                {!showStatus ? (
                    <>
                        <h2 style={styles.sectionHeading}>Contact Us</h2>
                        <p style={styles.contactText}>
                            We’d love to hear your feedback and ideas! Reach out to us with your thoughts.
                        </p>
                        <form style={styles.contactForm} onSubmit={handleSubmit}>
                            <textarea
                                placeholder="Your Message"
                                style={styles.textarea}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                            <Button
                                type="submit"
                                text="Send Message"
                                buttonType="primary"  // Use the 'primary' color scheme
                            />
                        </form>
                    </>
                ) : (
                    <p style={styles.statusMessage}>{status}</p>
                )}
            </div>
        </section>
    );
};

const styles = {
    contactSection: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
    },
    card: {
        position: 'relative', // Make it relative to contain the absolute close button
        backgroundColor: '#fff',
        padding: '30px',
        borderRadius: '10px',
        maxWidth: '500px',
        width: '100%',
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '24px',
        color: '#333',
        cursor: 'pointer',
    },
    sectionHeading: {
        fontSize: '28px',
        color: '#2c3e50',
        marginBottom: '20px',
    },
    contactText: {
        fontSize: '18px',
        color: '#7f8c8d',
        marginBottom: '20px',
    },
    contactForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        height: '100px',
        marginBottom: '20px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    statusMessage: {
        marginTop: '20px',
        fontSize: '16px',
        transition: 'opacity 1s ease-out',
    },
};

export default ContactForm;
