import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext';

const Header = () => {
    // const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    // const handleLogout = async () => {
    //     try {
    //         await logout();
    //         navigate('/login');
    //     } catch (error) {
    //         console.error("Failed to log out:", error);
    //     }
    // };

    return (
        <header style={styles.header}>
            <div style={styles.logo} onClick={() => navigate('/home')}> {/* Added onClick event */}
                <h1 style={styles.logoText}>BJJLocal</h1>
            </div>
            {/* <nav style={styles.nav}>
                <ul style={styles.navList}>
                    <li style={styles.navItem}><Link to="/home" style={styles.navLink}>Home</Link></li>
                    <li style={styles.navItem}><Link to="/news" style={styles.navLink}>News</Link></li>
                    <li style={styles.navItem}><Link to="/add-gym" style={styles.navLink}>Add Gym</Link></li>
                    {currentUser && (
                        <li style={styles.navItem}><Link to="/profile" style={styles.navLink}>Profile</Link></li>
                    )}
                </ul>
            </nav>
            <div style={styles.auth}>
                {currentUser ? (
                    <button onClick={handleLogout} style={styles.authButton}>Logout</button>
                ) : (
                    <>
                        <button onClick={() => navigate('/login')} style={styles.authButton}>Login</button>
                        <button onClick={() => navigate('/signup')} style={styles.signupButton}>Sign Up</button>
                    </>
                )}
            </div> */}
        </header>
    );
};

const styles = {
    header: {
        minHeight: '10vh',
        backgroundColor: '#2c3e50',
        color: '#ecf0f1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', 
    },
    logo: {
        fontSize: '24px',
        fontWeight: 'bold',
        cursor: 'pointer', // Make the cursor a pointer to indicate clickability
    },
    logoText: {
        margin: 0,
    },
    nav: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    navList: {
        display: 'flex',
        listStyleType: 'none',
        gap: '20px',
        margin: 0,
        padding: 0,
    },
    navItem: {
        fontSize: '18px',
    },
    navLink: {
        textDecoration: 'none',
        color: '#ecf0f1',
        fontWeight: 'bold',
        transition: 'color 0.3s ease',
    },
    navLinkHover: {
        color: '#f39c12',
    },
    auth: {
        display: 'flex',
        gap: '10px',
    },
    authButton: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#3498db',
        color: '#ecf0f1',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    signupButton: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#e74c3c',
        color: '#ecf0f1',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
};

export default Header;
